import logo from './logo.svg';
import './App.css';
import Home from './Components/Home';
 
import Login from './Components/Login';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomeNew from './Components/HomeNew';
import HomeNewDatesRange from './Components/HomeNewDatesRange';
import RenewHome from './Components/RenewHome';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login/>}/>
        {/* <Route path='home' element={<Home/>}/> */}
        {/* <Route path='home' element={<HomeNewDatesRange/>}/> */}
        <Route path='home' element={<RenewHome/>}/>

      </Routes>
      </BrowserRouter>
      {/* <Login/> */}
       {/* <Home/> */}
    </div>
  );
}

export default App;
